<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.editInstallation') }}</h5>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.active') }}</label>
                    <div class="p-col-12 p-md-2">
                        <InputSwitch v-model="terminal.active" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.id') }}</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" type="text" v-model="terminal._id" disabled />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.pcName') }}</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" type="text" v-model="terminal.pcName" disabled />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.registeredOn') }} </label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" type="text" v-model="terminal.registeredOn" disabled />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.authorizedOn') }} </label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" type="text" v-model="terminal.authorizedOn" disabled />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.registrationComments') }} </label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" type="text" v-model="terminal.registrationComments" disabled />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.shops') }} *</label>
                    <div class="p-col-12 p-md-2">
                        <Dropdown style="width: 100%" v-model="shopsModel" optionLabel="name" :options="shops" :placeholder="$t('dropdown.placeholder.shops')" :filter="!this.isMobile()" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <div class="p-col-12">
                        <p>{{ $t('forms.mandatoryFieldsText') }}</p>
                    </div>
                </div>
                <transition-group name="p-message" tag="div">
                    <Message :key="errorKeyCount" :severity="'error'" v-show="showError">{{ errorText }}</Message>
                </transition-group>
                <Button :label="$t('buttons.remove')" class="p-button-raised p-button-info p-mr-2 p-mb-2" @click="deleteTerminal()" />
                <Button @click="submitForm()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                <Button :label="$t('buttons.cancel')" class="p-button-raised p-button-danger p-mr-2 p-mb-2" @click="goto(backRoute)" />
            </div>
        </div>
    </div>
</template>
<script>
import { BoApi } from '../../service/BoApi';
export default {
    data() {
        return {
            BoApi: new BoApi(),
            showError: false,
            errorText: '',
            disableSubmit: false,
            backRoute: 'installations',
            errorKeyCount: 0,
            terminal: {
                active: false,
            },
            shops: [],
            shopsModel: {},
            successReset: false,
            passwordKeyCount: 0,
        };
    },
    watch: {
        shopsModel: function () {
            this.showError = false;
        },
    },
    mounted() {
        this.BoApi.getShops('')
            .then((responseShop) => {
                this.shops = responseShop.data;
                if (this.isEdit()) {
                    this.BoApi.getTerminal(this.$route.params.id).then((response) => {
                        this.terminal = response.data;
                        this.terminal.registeredOn = this.formatCSDateAndTime(this.terminal.registeredOn);
                        this.terminal.authorizedOn = this.formatCSDateAndTime(this.terminal.authorizedOn);
                        this.shopsModel = this.notNullOrEmptyField(this.terminal.shopId) ? this.shops.find((b) => b._id === this.terminal.shopId) : {};
                    });
                }
            })
            .catch((error) => {
                this.checkForUnauthorizedError(error);
                this.errorText = error;
                this.showError = true;
            });
    },
    methods: {
        submitForm() {
            this.showError = false;
            this.disableSubmit = true;
            if (this.notNullOrEmptyObject(this.shopsModel)) {
                this.terminal.shopId = this.shopsModel._id;
                this.BoApi.saveTerminal(this.terminal)
                    .then(() => {
                        this.goto(this.backRoute);
                    })
                    .catch((error) => {
                        this.disableSubmit = false;
                        this.checkForUnauthorizedError(error);
                        this.showError = true;
                        this.errorText = error;
                        this.errorKeyCount++;
                    });
            } else {
                this.errorText = this.$t('forms.mandatoryFieldsMessage');
                this.showError = true;
                this.disableSubmit = false;
                this.errorKeyCount++;
            }
        },
        isEdit() {
            return this.$route.params.id !== '0';
        },
        deleteTerminal() {
            this.BoApi.deleteTerminal(this.terminal._id)
                .then(() => {
                    this.goto(this.backRoute);
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showError = true;
                    this.disableSubmit = false;
                    this.errorKeyCount++;
                });
        },
    },
};
</script>
